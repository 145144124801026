import { BehaviorSubject, Observable } from 'rxjs';
import { Role, User } from 'app/auth/models';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  //public
  public currentUser: Observable<User>;

  //private
  private currentUserSubject: BehaviorSubject<User>;

  /**
   *
   * @param {HttpClient} _http
   * @param {ToastrService} _toastrService
   */
  constructor(
    private _http: HttpClient,
    private _toastrService: ToastrService,
    private fireAuth: AngularFireAuth,
    private adb: AngularFirestore,
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser')),
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  // getter: currentUserValue
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  /**
   *  Confirms if user is admin
   */
  get isAdmin() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Admin
    );
  }

  /**
   *  Confirms if user is client
   */
  get isClient() {
    return (
      this.currentUser && this.currentUserSubject.value.role === Role.Client
    );
  }

  public getProfile(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.adb
        .collection('users')
        .doc(id)
        .get()
        .subscribe(
          (profile: any) => {
            resolve(profile.data());
          },
          error => {
            reject(error);
          },
        );
    });
  }
  /**
   * User login
   *
   * @param email
   * @param password
   * @returns user
   */
  login(email: string, password: string) {
    return this.fireAuth.signInWithEmailAndPassword(email, password).then(u => {
      const userDetails: User = {} as User;
      const user = u.user;
      this.getProfile(user.uid).then(res => {
        userDetails.id = user.uid;
        userDetails.email = user.email;
        userDetails.token = user.refreshToken;
        userDetails.password = null;
        userDetails.avatar = 'avatar-s-11.jpg';
        userDetails.firstName = res.fullname;
        userDetails.lastName = '';
        userDetails.role = res.type;

        // login successful if there's a jwt token in the response
        if (user) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(userDetails));

          // Display welcome toast!
          setTimeout(() => {
            this._toastrService.success(
              'You have successfully logged in as an ' +
                userDetails.role +
                ' user.',
              '👋 Welcome, ' + userDetails.firstName + '!',
              { toastClass: 'toast ngx-toastr', closeButton: true },
            );
          }, 2500);

          // notify
          this.currentUserSubject.next(userDetails);
        }

        return userDetails;
      });
    });
  }

  /**
   * User logout
   *
   */
  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    // notify
    this.currentUserSubject.next(null);
    this.fireAuth.signOut();
  }
}
