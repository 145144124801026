export class NotificationsFakeData {
  public static data = {
    messages: [
      {
        image: 'assets/images/portrait/small/avatar-s-15.jpg',
        heading: '<span class="font-weight-bolder">New order</span>!',
        text: 'View orders page for more.',
      },
    ],
    systemMessages: [
      {
        icon: 'x',
        heading:
          '<span class="font-weight-bolder">Server down</span> registered',
        text: 'USA Server is down due to hight CPU usage',
      },
      {
        icon: 'check',
        heading:
          '<span class="font-weight-bolder">Sales report</span> generated',
        text: 'Last month sales report generated',
      },
      {
        icon: 'alert-triangle',
        heading: '<span class="font-weight-bolder">High memory</span> usage',
        text: 'BLR Server using high memory',
      },
    ],
    system: true,
  };
}
