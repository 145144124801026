export const environment = {
  production: true,
  hmr: false,
  apiUrl: 'http://localhost:4000',
  firebase: {
    apiKey: 'AIzaSyArXL3kB-3QQwTxD4NZuTfJ5uyAILiZGBs',
    authDomain: 'nitro-35119.firebaseapp.com',
    databaseURL: 'https://nitro-35119.firebaseio.com',
    projectId: 'nitro-35119',
    storageBucket: 'nitro-35119.appspot.com',
    messagingSenderId: '1006775311021',
    appId: '1:522025363066:web:d84d2bbb8ca7c103833dc3',
    measurementId: 'G-NJ5C01CGW1',
  },
};
