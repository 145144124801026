import 'hammerjs';

import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';
import { ErrorInterceptor, JwtInterceptor } from 'app/auth/helpers';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AnimatedCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/animated-custom-context-menu/animated-custom-context-menu.component';
import { AppComponent } from 'app/app.component';
import { AuthGuard } from 'app/auth/helpers/auth.guards';
import { BasicCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/basic-custom-context-menu/basic-custom-context-menu.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CardSnippetModule } from '@core/components/card-snippet/card-snippet.module';
import { ContentHeaderModule } from 'app/layout/components/content-header/content-header.module';
import { ContextMenuComponent } from 'app/main/extensions/context-menu/context-menu.component';
import { ContextMenuModule } from '@ctrl/ngx-rightclick';
import { CoreCommonModule } from '@core/common.module';
import { CoreModule } from '@core/core.module';
import { FakeDbService } from '@fake-db/fake-db.service';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { LayoutModule } from 'app/layout/layout.module';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SubMenuCustomContextMenuComponent } from './main/extensions/context-menu/custom-context-menu/sub-menu-custom-context-menu/sub-menu-custom-context-menu.component';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule } from '@ngx-translate/core';
import { coreConfig } from 'app/app-config';
import { environment } from 'environments/environment.prod';
import { fakeBackendProvider } from 'app/auth/helpers'; // used to create fake backend

const appRoutes: Routes = [
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'apps',
    loadChildren: () =>
      import('./main/apps/apps.module').then(m => m.AppsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./main/pages/pages.module').then(m => m.PagesModule),
  },
  {
    path: 'ui',
    loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'components',
    loadChildren: () =>
      import('./main/components/components.module').then(
        m => m.ComponentsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'extensions',
    loadChildren: () =>
      import('./main/extensions/extensions.module').then(
        m => m.ExtensionsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'forms',
    loadChildren: () =>
      import('./main/forms/forms.module').then(m => m.FormsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'tables',
    loadChildren: () =>
      import('./main/tables/tables.module').then(m => m.TablesModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'charts-and-maps',
    loadChildren: () =>
      import('./main/charts-and-maps/charts-and-maps.module').then(
        m => m.ChartsAndMapsModule,
      ),
    canActivate: [AuthGuard],
  },
  {
    path: '',
    redirectTo: '/dashboard/ecommerce',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error', //Error 404 - Page not found
  },
];

@NgModule({
  declarations: [
    AppComponent,
    ContextMenuComponent,
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
    SubMenuCustomContextMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    HttpClientModule,
    HttpClientInMemoryWebApiModule.forRoot(FakeDbService, {
      delay: 0,
      passThruUnknownUrl: true,
    }),
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled', // Add options right here
      relativeLinkResolution: 'legacy',
    }),
    NgbModule,
    ToastrModule.forRoot(),
    TranslateModule.forRoot(),
    ContextMenuModule,
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,
    CardSnippetModule,
    LayoutModule,
    ContentHeaderModule,
  ],

  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },

    // ! IMPORTANT: Provider used to create fake backend, comment while using real API
    fakeBackendProvider,
  ],
  entryComponents: [
    BasicCustomContextMenuComponent,
    AnimatedCustomContextMenuComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
